// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-copy-js": () => import("./../../../src/pages/404 copy.js" /* webpackChunkName: "component---src-pages-404-copy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blogs-page-js": () => import("./../../../src/templates/blogs-page.js" /* webpackChunkName: "component---src-templates-blogs-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-customer-page-js": () => import("./../../../src/templates/customer-page.js" /* webpackChunkName: "component---src-templates-customer-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-main-showcase-page-js": () => import("./../../../src/templates/main-showcase-page.js" /* webpackChunkName: "component---src-templates-main-showcase-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/products-page.js" /* webpackChunkName: "component---src-templates-products-page-js" */)
}

